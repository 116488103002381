import { graphql, useStaticQuery } from 'gatsby';
import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { staticGlobals } from '~/config';

type SEOProps = {
  title?: string;
  description?: string;
  url?: string;
  keywords?: string[];
  landscapeOgImage?: string;
};

export const SEO: FC<SEOProps> = ({
  title,
  description,
  url,
  keywords = [],
  landscapeOgImage,
}) => {
  const { site } = useStaticQuery<Queries.SEOQueryQuery>(
    graphql`
      query SEOQuery {
        site {
          siteMetadata {
            siteUrl
            title
            description
            author
          }
        }
      }
    `,
  );

  const response = site?.siteMetadata;
  const metaTitle = title || response?.title;
  const metaDescription = description || response?.description;
  const metaAuthor = response?.author || "y'all";
  const metaUrl = url || response?.siteUrl || staticGlobals.defaults.siteUrl;
  const metaOgLandscape =
    landscapeOgImage || staticGlobals.defaults.defaultLandscapeOgImage;

  return (
    <Helmet
      htmlAttributes={{ lang: 'en' }}
      title={metaTitle}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        { name: 'author', content: metaAuthor },
        {
          property: 'og:title',
          content: metaTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: `og:image`,
          content: metaOgLandscape,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:creator',
          content: staticGlobals.defaults.twitterHandle,
        },
        {
          name: 'twitter:title',
          content: metaTitle,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          /*
            Important information on this setting:
  
            We originally set this to a square image (that can be reinstated if needed, use git-blame to
            find this PR) but at least in the current garbage-fire state of Twitter, the landscape image
            ended up looking better, confirmed in their docs which state 2:1 ratio.
          */
          name: 'twitter:image',
          content: metaOgLandscape,
        },
        {
          name: 'theme-color',
          content: '#45413C',
        },
      ].concat(
        keywords.length > 0
          ? {
              name: 'keywords',
              content: keywords.join(', '),
            }
          : [],
      )}
    >
      {/* Scripts here - browser-only scripts should be gated with !isSSG && !isNetlify */}
    </Helmet>
  );
};
