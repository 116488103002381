import { AnimatePresence, motion } from 'framer-motion';
import { PageProps as GatsbyPageProps } from 'gatsby';
import { FC, useContext, useEffect, useMemo } from 'react';
import tw, { screen } from 'twin.macro';
import { JauntyButton } from '~/components/atoms/JauntyButton';
import { SEO } from '~/components/SEO';
import { isSSG } from '~/config';
import { DayContext } from '~/context/DayContext';
import { ThemeContext } from '~/context/ThemeContext';
import { anim, easeOutQuart, easeOutQuint, variant } from '~/utils';

/**
 * A simplified version of the Day.tsx template reformatted for displaying our 404 message
 * Ideally, this would not be copy-pasta, but Day.tsx has too much logic built into it
 */
const FourOhFour: FC<GatsbyPageProps> = () => {
  const { setTheme } = useContext(ThemeContext);
  const { ready, requestByPath, todayPath } = useContext(DayContext);

  const noDataToday = !isSSG && window.location.pathname === todayPath;

  const title = `You’ve reached the limits of the space-time continuum.`;
  const description = `While we wish we could say we were metaphysical physicists, we can’t. We’re just a multidisciplinary agency trying to do some good in the world.`;

  useEffect(() => {
    setTheme();
    // Only fire once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const blurbDelay = 0.43;

  const variants = useMemo(() => {
    const show = {
      opacity: 1,
      y: 0,
    };

    const hideBackward = {
      opacity: 0,
      y: 15,
    };

    const hideForward = {
      opacity: 0,
      y: -15,
    };

    const hide = hideBackward;
    const initial = hideForward;

    return {
      name: {
        leave: anim(hide, 0.15),
        enter: anim(show, 0.8, blurbDelay, easeOutQuint),
        initial,
      },
      blurb: {
        leave: anim(hide, 0.15),
        enter: anim(show, 0.85, blurbDelay + 0.05, easeOutQuint),
        initial,
      },
      cta: {
        leave: anim(hide, 0.15),
        enter: anim(show, 0.9, blurbDelay + 0.15, easeOutQuint),
        initial,
      },
    };
  }, []);

  const handleCTAClick = () => {
    if (noDataToday) {
      requestByPath('/day/2023-01-01/');
    } else {
      requestByPath(todayPath);
    }
  };

  return (
    <AnimatePresence>
      <SEO title={title} description={description} />
      <Section>
        <div tw="relative w-full text-center sm:(max-w-xl)">
          <div tw="relative pt-4 px-6">
            <DateOops ready={ready} />

            {/* TITLE & ICON */}
            <motion.h1
              tw="m-0 font-primary text-theme-fg sm:text-theme-base text-[1.625rem] leading-[1.25] sm:text-[2rem]"
              {...variant(variants.name, ready)}
            >
              {title}
            </motion.h1>

            {/* BODY COPY */}
            <motion.div
              css={[
                tw`font-secondary text-[0.9375rem] leading-[1.73]`,
                screen`sm`(tw`text-[1.125rem] leading-[1.66]`),
                `
                > p {
                  margin-top: 0.825rem;
                  font-size: inherit;
                  line-height: inherit;
                }
              `,
              ]}
              {...variant(variants.blurb, ready)}
            >
              <p>
                While we wish we could say we were metaphysical physicists, we
                can’t. We’re just{' '}
                <a href="https://itsyall.com" tw="underline">
                  a multidisciplinary agency
                </a>{' '}
                trying to do some good in the world. We haven’t logged a daily
                difference for this date yet. Click that handy button below to
                hop back to{' '}
                {noDataToday ? `the beginning of time` : `today’s date`}. Keep
                adventuring—and keep making a difference while you’re at it!
              </p>
            </motion.div>

            {/* CTA BUTTON */}
            <motion.div {...variant(variants.cta, ready)}>
              <div tw="flex gap-2 justify-center items-center sm:block">
                <JauntyButton
                  customCss={[
                    tw`max-w-[20rem] mt-3 sm:(text-lg) leading-[1.1]`,
                  ]}
                  onClick={handleCTAClick}
                >
                  <span tw="block leading-none py-2">
                    {noDataToday ? `TO THE BEGINNING OF TIME` : `WHAT’S TODAY?`}
                  </span>
                </JauntyButton>
              </div>
            </motion.div>
          </div>
        </div>
      </Section>
    </AnimatePresence>
  );
};

const Section = tw.section`flex flex-col items-center justify-start w-full sm:(w-auto)`;

/**
 * A simplified DateTicker that only says "OOPS"
 */
export const DateOops: FC<{ ready: boolean }> = ({ ready }) => {
  const variants = useMemo(() => {
    const hideBackward = {
      opacity: 0,
      y: -5,
    };

    const hideForward = {
      opacity: 0,
      y: 5,
    };

    const hide = 1 > 0 ? hideForward : hideBackward;
    const initial = 1 > 0 ? hideBackward : hideForward;

    const show = {
      opacity: 1,
      y: 0,
    };

    return {
      ready: {
        leave: anim({ opacity: 0, y: 0 }, 0), // instant
        enter: anim(
          { opacity: [0, 1, 1, 1], y: [0, 0, -15, 0] },
          1.2,
          0,
          easeOutQuart,
          [0, 0, 0.3, 1],
        ),
        initial: { opacity: 0, y: 0 },
      },
      date: {
        dayOfWeek: {
          leave: anim(hide, 0.1),
          enter: anim(show, 0.8, 0.5, easeOutQuint),
          initial,
        },
        monthDigits: {
          leave: anim(hide, 0.1, 0),
          enter: anim(show, 0.2, 0, easeOutQuint),
          initial,
        },
      },
    };
  }, []);

  return (
    <div tw="w-full sm:(pt-0) text-center">
      <motion.h2
        tw="relative text-[7.75rem] leading-[0.8] text-theme-fg"
        key="ooooops"
        {...variant(variants.ready, ready)}
      >
        <span tw="relative block">
          <motion.span
            tw="flex justify-center"
            key="oo"
            {...variant(variants.date.monthDigits)}
          >
            <span tw="block w-[5.1rem]">O</span>
            <span tw="block w-[5.1rem]">O</span>
          </motion.span>
        </span>
        <span tw="relative block">
          <span tw="flex justify-center">
            <span tw="block w-[4.6rem]">P</span>
            <span tw="block w-[4.5rem]">S</span>
          </span>
        </span>
      </motion.h2>
    </div>
  );
};

export default FourOhFour;
